




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection_transfers } from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({ components: { Card } })
export default class InspectionTransfers extends Vue {
  @Prop(Array) protected transfers!: Inspection_inspection_transfers[];
}
