























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection, InspectionApprovalState, InspectionStatus } from '@/types/intrador';

@Component({})
export default class InspectionApproval extends Vue {
  @Prop(Object) protected inspection!: Inspection_inspection;

  get isDeclinedOrAccepted() {
    return this.isAccepted || this.isDeclined;
  }

  get isAutomaticallyAccepted() {
    return this.inspection.automaticallyAcceptedAt !== null;
  }

  get isAccepted() {
    return this.inspection.status === InspectionStatus.ACCEPTED;
  }

  get isDeclined() {
    return this.inspection.status === InspectionStatus.DECLINED;
  }

  get lastApproval() {
    // If we do not have approvals, stop
    if (!this.inspection.approvals) {
      return null;
    }

    // If not accepted or declined, stop
    if (!this.isAccepted && !this.isDeclined) {
      return null;
    }

    // Get last approval based on state
    const approvals = this.inspection.approvals.filter((a) => a.state === (this.isAccepted
      ? InspectionApprovalState.ACCEPTED
      : InspectionApprovalState.DECLINED));

    return approvals.length > 0 ? approvals[0] : null;
  }
}
