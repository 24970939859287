






























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class InspectionParameterState extends Vue {
  @Prop(String) protected opid!: string;
  @Prop(String) protected value!: string;
}
