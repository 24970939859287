




import {Component, Mixins, Prop} from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import AuctionDetailModal from '@/components/auction/modals/AuctionDetailModal.vue';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';

@Component({
  components: {AuctionDetailModal},
})
export default class AuctionDetailActionModal extends Mixins(ActionMixin) {
  public title: string = this.$it('auction.show.title', 'Show sale') as string;
  public icon: string = 'gavel';
  public permission: string = 'auctions-get';

  public open() {
    const modal: ActionModal = this.$refs.modal as ActionModal;

    modal.open();
  }

  public onSelect() {
    this.open();
  }
}
