


















import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import ActionButton from '@/layouts/back-office/elements/actions/ActionButton.vue';
import CreateAuctionAction from '@/components/auction/actions/CreateAuctionAction.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import {SmartQuery} from 'vue-apollo/types/vue-apollo';
import AuctionDetailModal from '@/components/auction/modals/AuctionDetailModal.vue';
import ActionsRegistrarMixin, {ActionItem} from '@/layouts/back-office/elements/actions/ActionsRegistrarMixin';
import AuctionDetailActionModal from '@/components/auction/actions/AuctionDetailActionModal.vue';

@Component({
  components: {AuctionDetailActionModal, AuctionDetailModal, SpinnerButton, CreateAuctionAction, ActionButton},
})
export default class ShowOrCreateAuction extends Vue {
  @Prop({type: Array, default: () => []}) public selected!: any[];
  @Prop({type: Function, required: false }) protected register!: (item: ActionItem) => ActionsRegistrarMixin;

  @Prop(String) protected flowId?: string;
  @Prop({type: Array, default: () => []}) protected parameterValueIds!: string[];

  public async refetch() {
    if (this.queryResult) {
      await this.queryResult.refetch();
    }
  }

  public async onAuctionCreated() {
    await this.refetch();

    const model = this.$refs['auction-detail'] as AuctionDetailActionModal;
    model.open();
  }

  protected get variables() {
    return {assetId: this.selected[0].id};
  }

  private get queryResult(): SmartQuery<any> | null {
    if (this.$refs.apolloQuery) {
      return (this.$refs.apolloQuery as any).getApolloQuery();
    }

    return null;
  }
}
