
























import { Component, Mixins, Prop } from 'vue-property-decorator';
import ActionMixin from '../../../layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '../../../layouts/back-office/elements/actions/ActionModal.vue';
import {EquipmentSituation, InspectionStatus} from '@/types/intrador';

@Component({
  components: {
    ActionModal,
  },
})
export default class HoldInspection extends Mixins<ActionMixin<any>>(ActionMixin) {
  public permission: string = 'inspections-on-hold';
  @Prop(String) protected status!: InspectionStatus;
  protected message: string | null = null;

  public open() {
    this.message = null;

    (this.$refs.actionModal as ActionModal).open();
  }

  get modalTitle() {
    return (this.isOnHold)
      ? this.$it('inspection.action.resolve-hold.title', 'Resolve inspection on hold') as string
      : this.$it('inspection.action.hold.title', 'Put inspection on hold') as string;
  }

  get isOnHold() {
    return this.status === InspectionStatus.ON_HOLD;
  }

  get availableItems(): any[] {
    return this.status === InspectionStatus.ON_HOLD
      ? this.selected.filter((inspection: any) => inspection.status === InspectionStatus.SUBMITTED
        || inspection.status === InspectionStatus.DECLINED || inspection.status === InspectionStatus.ON_HOLD
        || (inspection.equipmentSituation === EquipmentSituation.TRANSFERRED
          && (inspection.status === InspectionStatus.PLANNED || inspection.status === InspectionStatus.EXPIRED)))

      : this.selected.filter((inspection: any) => inspection.status === InspectionStatus.SUBMITTED
        || inspection.status === InspectionStatus.ON_HOLD ||
        (inspection.equipmentSituation === EquipmentSituation.TRANSFERRED &&
          (inspection.status === InspectionStatus.PLANNED || inspection.status === InspectionStatus.EXPIRED)));
  }

  get refetchQueries() {
    // The refetch query for when he is on hold gives a strange result
    if (this.status === InspectionStatus.ON_HOLD) {
      return null;
    }

    return [{
      query: require('@/graphql/queries/inspection/Inspection.gql'),
      variables: {
        id: this.selected[0].id,
        displayType: 'report',
      },
    }];
  }

  get canSave() {
    if (this.isOnHold) {
      return true;
    }
    return !!this.message;
  }

  get variables() {
    if (this.isOnHold) {
      return {
        id: this.selected[0].id,
      };
    }
    return {
      id: this.selected[0].id,
      message: this.message,
    };
  }

  get variableIcon(): string | undefined {
    return (this.isOnHold) ? 'angle-double-right' : 'pause-circle';
  }

  protected onSelect() {
    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    this.message = null;

    modal.open();
  }

  protected done() {
    this.$emit('done', this.$it('inspection.action.success', 'Inspection updated successfully') as string);
  }
}

