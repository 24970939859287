





















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';
import InspectionFormatter from '@/plugins/inspection/InspectionFormatter';
import InspectionParameterState from '@/components/inspection/InspectionParameterState.vue';
import GripProxy from '@/plugins/misc/GripProxy';

@Component({components: {InspectionParameterState, Card}})
export default class InspectionParameters extends Vue {

  @Prop(Object) protected formatter!: InspectionFormatter;
  @Prop(Object) protected gripProxy!: GripProxy;
  private readonly MAPS_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

  // TODO: implement
  // private AIFoundAProblem(value: any) {
  //   this.$nextTick(function() {
  //     if (this.gripProxy) {
  //       value.images.forEach((image: any) => {
  //         const predictionData = this.gripProxy.getDataForImageForParameter(image.id.toString(),
  //           value.parameter.id.toString());
  //       });
  //     }
  //   });
  // }

}
