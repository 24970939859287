
















import {Component, Prop, Vue} from 'vue-property-decorator';
import { Inspection_inspection_address } from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({ components: { Card } })
export default class InspectionAddress extends Vue {
  @Prop(Object) private address!: Inspection_inspection_address;

  private get url() {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${this.address.latitude},${this.address.longitude}`
      + `&size=900x141&scale=2&zoom=7&markers=red|${this.address.latitude},${this.address.longitude}`
      + `&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`;
  }
}
