







import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection } from '@/types/intrador';
import SnapshotCheck, { SnapshotCheckType } from '@/components/snapshot/anomalies/SnapshotCheck.vue';

@Component({ components: { SnapshotCheck } })
export default class CheckSerialNumberManually extends Vue {
  @Prop(Object) protected inspection!: Inspection_inspection;

  get manualCheck() {
    return this.inspection.checks!.find((check) =>
      check.namespace === 'SerialNumber' &&
      (check.message === 'MANUALLY_CHECKED' || check.message === 'NOT_READABLE'));
  }

  get type() {
    if (!this.manualCheck) {
      return SnapshotCheckType.NO_INFORMATION;
    }

    switch (this.manualCheck.level) {
      case 'ERROR':
        return SnapshotCheckType.ERROR;
      case 'WARNING':
        return SnapshotCheckType.WARNING;
    }

    return SnapshotCheckType.SUCCESS;
  }

  get content() {
    if (!this.manualCheck) {
      return this.$it('snapshot.detail.anomaly.manual-check.not-checked', 'Not checked yet');
    }

    if (this.manualCheck.message === 'NOT_READABLE') {
      return this.$it('snapshot.detail.anomaly.manual-check.not-readable.info', 'Not readable');
    }

    const replace = { date: this.$id(this.manualCheck!.createdAt!, 'date') };
    return this.$it('snapshot.detail.anomaly.manual-check.info', replace, 'Verified at {date}');
  }
}
