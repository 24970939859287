



















































































































































































import { Component, Vue } from 'vue-property-decorator';
import {
  Inspection_inspection,
  InspectionApprovalState,
  InspectionStatus,
  InspectionVariables,
} from '@/types/intrador';
import ActionBar from '@/layouts/back-office/elements/ActionBar.vue';
import ActionButton from '@/layouts/back-office/elements/actions/ActionButton.vue';
import ActionMenu, { ActionMenuDirection } from '@/layouts/back-office/elements/ActionMenu.vue';
import ApproveInspections from '@/components/inspection/actions/ApproveInspections.vue';
import Detail from '@/layouts/back-office/main/Detail.vue';
import DownloadReport from '@/components/inspection/actions/DownloadReport.vue';
import DownloadImages from '@/components/inspection/actions/DownloadImages.vue';
import ExtendInspection from '@/components/inspection/actions/ExtendInspection.vue';
import HoldInspection from '@/components/inspection/actions/HoldInspection.vue';
import ImageViewer from '@/components/ImageViewer.vue';
import InspectionAddress from '@/components/inspection/InspectionAddress.vue';
import InspectionAnomalies from '@/components/inspection/InspectionAnomalies.vue';
import InspectionApproval from '@/components/inspection/InspectionApproval.vue';
import InspectionDamages from '@/components/inspection/InspectionDamages.vue';
import InspectionDetails from '@/components/inspection/InspectionDetails.vue';
import InspectionDevices from '@/components/inspection/InspectionDevices.vue';
import InspectionFormatter from '@/plugins/inspection/InspectionFormatter';
import InspectionHold from '@/components/inspection/InspectionHold.vue';
import InspectionImages from '@/components/inspection/InspectionImages.vue';
import InspectionInspector from '@/components/inspection/InspectionUserView.vue';
import InspectionParameters from '@/components/inspection/InspectionParameters.vue';
import InspectionPhotoAnomalies from '@/components/inspection/InspectionPhotoAnomalies.vue';
import InspectionRelated from '@/components/inspection/InspectionRelated.vue';
import InspectionRemark from '@/components/inspection/InspectionRemark.vue';
import InspectionStatusLabel from '@/components/inspection/InspectionStatusLabel.vue';
import InspectionSyncStatus from '@/components/inspection/InspectionSyncStatus.vue';
import InspectionTimings from '@/components/inspection/InspectionTimings.vue';
import InspectionUserView from '@/components/inspection/InspectionUserView.vue';
import SubmitInspections from '@/components/inspection/actions/SubmitInspections.vue';
import TransferInspections from '@/components/inspection/actions/TransferInspections.vue';
import TrustedLabel from '@/components/TrustedLabel.vue';
import InspectionUpdates from '@/components/inspection/InspectionUpdates.vue';
import InspectionTransfers from '@/components/inspection/InspectionTransfers.vue';
import SendInspectionsReminderEmailAction from '@/components/dealer/actions/SendInspectionsReminderEmailAction.vue';
import NotesCard from '@/components/notes/NotesCard.vue';
import CreateNote from '@/components/notes/Actions/CreateNote.vue';
import Card from '@/layouts/back-office/elements/Card.vue';
import CancelInspections from '@/components/inspection/actions/CancelInspections.vue';
import AppraisalsCard from '@/components/appraisal/AppraisalsCard.vue';
import BulkCreateAppraisals from '@/components/appraisal/actions/BulkCreateAppraisals.vue';
import ShowOrCreateAuction from '@/components/auction/actions/ShowOrCreateAuction.vue';
import GripProxy from '@/plugins/misc/GripProxy';

@Component({
  components: {
    ShowOrCreateAuction,
    BulkCreateAppraisals,
    AppraisalsCard,
    CancelInspections,
    Card,
    CreateNote,
    NotesCard,
    ActionBar,
    ActionButton,
    ActionMenu,
    ApproveInspections,
    Detail,
    DownloadReport,
    DownloadImages,
    ExtendInspection,
    HoldInspection,
    ImageViewer,
    InspectionAddress,
    InspectionAnomalies,
    InspectionApproval,
    InspectionDamages,
    InspectionDetails,
    InspectionDevices,
    InspectionHold,
    InspectionImages,
    InspectionInspector,
    InspectionParameters,
    InspectionPhotoAnomalies,
    InspectionRelated,
    InspectionRemark,
    InspectionStatusLabel,
    InspectionSyncStatus,
    InspectionTimings,
    InspectionTransfers,
    InspectionUpdates,
    InspectionUserView,
    SendInspectionsReminderEmailAction,
    SubmitInspections,
    TransferInspections,
    TrustedLabel,
  },
})
export default class InspectionDetail extends Vue {
  private ActionMenuDirection = ActionMenuDirection;
  private InspectionApprovalState = InspectionApprovalState;
  private InspectionStatus = InspectionStatus;

  private gripProxy: GripProxy | null = null;

  private formatter?: InspectionFormatter;
  private data?: Inspection_inspection;

  private get variables(): InspectionVariables {
    return {
      id: this.$route.params.id,
      displayType: 'report',
      entityOnly: true,
    };
  }

  private async created() {
    if (this.$can('ai-predictions-view')) {
      this.gripProxy = new GripProxy();
      this.gripProxy.setInspectionIID(this.$route.params.id);
      await this.gripProxy.getGripAIData();
    }
  }

  private get lastAppraisal() {
    if (this.data && this.data.appraisals && this.data.appraisals.length > 0) {
      const appraisalsLength = this.data.appraisals.length - 1;

      return this.data.appraisals[appraisalsLength];
    }
    return null;
  }

  private get flowId() {
    return (this.data && this.data.flow)
      ? this.data.flow.id
      : null;
  }

  private get parameterValueIds() {
    return (this.data && this.data.parameterValues)
      ? this.data.parameterValues.map((parameterValue) => parameterValue!.id)
      : [];
  }

  private getData(data: Inspection_inspection) {
    this.formatter = new InspectionFormatter(data);
    this.formatter.format();

    this.data = data;
  }

  private openImage(id: string) {
    (this.$refs.viewer as ImageViewer).open(id);
  }
}
