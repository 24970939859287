





























import { Component, Prop, Vue } from 'vue-property-decorator';
import Polaroid from '@/components/Polaroid.vue';
import Card from '@/layouts/back-office/elements/Card.vue';
import InspectionFormatter from '@/plugins/inspection/InspectionFormatter';

@Component({
  components: {Card, Polaroid},
})
export default class InspectionDamages extends Vue {
  @Prop(Object) protected formatter!: InspectionFormatter;

  public get damages() {
    return this.formatter.inspectionDamages.map((damage) => {
      return {
        ...damage,
        parts: [
          damage.costs ? `${this.$it('inspection.detail.damages.costs', 'Costs: ')} ${this.$in(damage.costs)}` : null,
          damage.costsForLessee ? this.$it('inspection.detail.damages.costs-for-lessee', 'Costs are for lessee') : null,
        ].filter((d) => !!d).join(' • '),
      };
    });
  }
}
