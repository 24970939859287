

























import { Component, Mixins } from 'vue-property-decorator';
import DownloadActionModal from '@/layouts/back-office/elements/actions/DownloadActionModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import LanguageSelect from '@/layouts/back-office/elements/input/InputLanguage.vue';
import i18n from '@/store/i18n';
import Boolean from '@/layouts/back-office/elements/input/InputBoolean.vue';
import Toggle from '@/components/elements/button/Toggle.vue';
import {snapshot_inspection_asset} from '@/types/intrador';
import slugify from '@/filters/vue/slugify';
import {ExportToggleChoice} from '@/components/elements/button/ToggleInterface';

enum ReportType {
  RE_MARKETEER = 1,
  EX_LESSEE = 2,
  COMMERCIAL = 3,
  DESPATCH_NOTE = 4,
  CHECK_OUT_CHECK_IN = 5,
}

@Component({
  components: {Toggle, Boolean, LanguageSelect, DownloadActionModal},
})
export default class DownloadReport extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title = this.$it('snapshot.action.download-report.title', 'Download report') as string;
  public icon?: string = 'file-pdf';

  private report: ReportType = ReportType.RE_MARKETEER;

  private reportTypes: {[key: string]: ExportToggleChoice} = {
    [ReportType.EX_LESSEE]: {
      label: this.$it('inspection.pdf.ex-lessee.title', 'Ex lessee report') as string,
      value: ReportType.EX_LESSEE,
      icon: 'fas fa-file-pdf',
      url:  '/inspections/pdfs/inspection/{id}/ex-lessee-report',
      permission: 'inspections-pdfs-ex-lease',
    },
    [ReportType.RE_MARKETEER]: {
      label: this.$it('inspection.pdf.re-marketeer.title', 'Re marketeer report') as string,
      value: ReportType.RE_MARKETEER,
      icon: 'fas fa-file-pdf',
      url:  '/inspections/pdfs/inspection/{id}/remarketer-report',
      permission: 'inspections-pdfs-re-marketing',
    },
    [ReportType.COMMERCIAL]: {
      label: this.$it('inspection.pdf.commercial.title', 'Commercial report') as string,
      value: ReportType.COMMERCIAL,
      icon: 'fas fa-file-pdf',
      url:  '/inspections/pdfs/inspection/{id}/commercial-report',
      permission: 'inspections-pdfs-commercial',
    },
    [ReportType.DESPATCH_NOTE]: {
      label: this.$it('inspection.pdf.despatch-note.title', 'Despatch note report') as string,
      value: ReportType.DESPATCH_NOTE,
      icon: 'fas fa-file-pdf',
      url:  '/inspections/pdfs/inspection/{id}/despatch-note-report',
      permission: 'inspections-pdfs-despatch-note',
    },
    [ReportType.CHECK_OUT_CHECK_IN]: {
      label: this.$it('inspection.pdf.check-out-check-in.title', 'Check-out / Check-in report') as string,
      value: ReportType.CHECK_OUT_CHECK_IN,
      icon: 'fas fa-file-pdf',
      url:  '/inspections/pdfs/inspection/{id}/check-out-check-in-report',
      permission: 'inspections-pdfs-check-out-check-in',
    },
  };

  private language: string = i18n.locale;
  private withLogo: boolean = true;
  private withImages: boolean = true;

  public mounted() {
    this.permission = this.availableReports
      .flatMap((item) => item.permission, [])
      .join('|');
  }

  get availableReports(): ExportToggleChoice[] {
    return Object.values(this.reportTypes);
  }

  get selectedReport(): ExportToggleChoice {
    const availableReports = this.availableReports;
    return availableReports.find((type) => type.value === this.report) ?? availableReports[0];
  }

  get urlTemplate(): string {
    return this.selectedReport.url;
  }

  get url() {
    return this.urlTemplate.replace('{id}', this.selected[0].id);
  }

  get filename(): string {
    const asset: snapshot_inspection_asset = this.selected[0].asset;
    const filename: string[] = [asset.name as string];
    const serialNumber = asset.serialNumber;

    if (serialNumber !== null) {
      filename.unshift(serialNumber);
    }

    const replace = {
      filename: slugify(filename.join('-').toLowerCase()),
    };

    switch (this.report) {
      case ReportType.RE_MARKETEER:
        return this.$it('inspection.pdf.re-marketeer.filename', replace, '{filename}-re-marketeer.pdf') as string;
      case ReportType.EX_LESSEE:
        return this.$it('inspection.pdf.ex-lessee.filename', replace, '{filename}-ex-lessee.pdf') as string;
      case ReportType.COMMERCIAL:
        return this.$it('inspection.pdf.commercial.filename', replace, '{filename}-commercial.pdf') as string;
      case ReportType.DESPATCH_NOTE:
        return this.$it('inspection.pdf.despatch-note.filename', replace, '{filename}-despatch-note.pdf') as string;
      case ReportType.CHECK_OUT_CHECK_IN:
        // Ignore this because the translator cannot work with the multiline translation
        // tslint:disable-next-line:max-line-length
        return this.$it('inspection.pdf.check-out-check-in.filename', replace, '{filename}-check-out-check-in.pdf') as string;
    }
  }

  get variables() {
    return {
      lang: this.language,
      withLogo: this.withLogo,
      withImages: this.withImages,
    };
  }

  protected onSelect() {
    (this.$refs.actionModal as DownloadActionModal).open();
  }
}
