

























import { Component, Mixins } from 'vue-property-decorator';
import {Inspection_inspection_parameterValues} from '@/types/intrador';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import Boolean from '@/layouts/back-office/elements/input/InputBoolean.vue';
import ConfirmActionModal from '@/layouts/back-office/elements/actions/ConfirmActionModal.vue';
import LanguageSelect from '@/layouts/back-office/elements/input/InputLanguage.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';

@Component({
  components: {ConfirmActionModal, Boolean, LanguageSelect, Modal, SpinnerButton},
})
export default class DownloadImages extends Mixins<ActionMixin<any>>(ActionMixin) {
  public title: string = this.$it('snapshot.action.download-media.title', 'Download Media') as string;
  public icon?: string = 'file-archive';
  public permission: string = 'inspections-download-images';

  private compressed: boolean = false;

  /**
   * The variables for the mutation
   *
   * @protected
   */
  protected get variables() {
    return {
      id: this.selected[0].id,
      compressed: this.compressed,
    };
  }

  /**
   * Whether the inspection actually has files to download
   *
   * @protected
   */
  protected get hasImages() {
    if (!this.selected[0] || !this.selected[0].parameterValues) {
      return false;
    }

    for (const i in this.selected[0].parameterValues || []) {
      if (this.selected[0].parameterValues[i]) {
        const pv = (this.selected[0].parameterValues[i] as Inspection_inspection_parameterValues);
        if (pv && pv.images && pv.images.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  protected onSelect() {
    (this.$refs.modal as Modal).open();
  }

  /*
   * We want to check "beforeMount" whether we should register this ActionItem,
   * since ActionItems get registered in ActionMixin "onMount"
  */
  private beforeMount() {
    if (!this.hasImages) {
      this.isActive = false;
    }
  }

  /**
   * Download the file
   *
   * @param url The URL where the file is located
   * @private
   */
  private download({downloadInspectionMedia}: { downloadInspectionMedia: string }) {
    // Download the file through a mimicked click
    const link = this.$refs.downloader as HTMLAnchorElement;
    link.setAttribute('download', '');
    link.href = downloadInspectionMedia;
    link.click();
  }
}
