


















































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Inspection_inspection_syncJob, InspectionStatus, SyncJobStatusEnum} from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({
  components: { Card },
})
export default class InspectionSyncStatus extends Vue {
  @Prop(Object) protected syncJob?: Inspection_inspection_syncJob;
  @Prop(Boolean) protected status!: InspectionStatus;

  private timer: number | null = null;

  private get isDesktop() {
    return !this.syncJob && this.status !== InspectionStatus.PLANNED && this.status !== InspectionStatus.EXPIRED;
  }

  private get isSyncing() {
    return this.syncJob?.status === SyncJobStatusEnum.IN_PROGRESS;
  }

  private get requestsDone() {
    if (!this.syncJob) {
      return 0;
    }

    return (
      (this.syncJob.numberOfRequests || 0) +
      (this.syncJob.numberOfImages || 0) +
      (this.syncJob.numberOfVideos || 0)
    );
  }

  private get percentage() {
    if (this.syncJob && this.syncJob?.numberOfRequests && this.syncJob?.numberOfRequestsDone) {
      return this.requestsDone / this.syncJob?.numberOfRequests * 100;
    }

    return null;
  }

  private get eta() {
    if (this.syncJob?.estimatedTimeRemaining) {
      let hours: number | string = Math.floor(this.syncJob?.estimatedTimeRemaining / 60 / 60);
      let minutes: number | string = Math.floor(this.syncJob?.estimatedTimeRemaining / 60 % 60);
      let seconds: number | string = this.syncJob?.estimatedTimeRemaining % 60;

      if (hours < 10) { hours = `0${hours}`; }
      if (minutes < 10) { minutes = `0${minutes}`; }
      if (seconds < 10) { seconds = `0${seconds}`; }

      return `${hours}:${minutes}:${seconds}`;
    }

    return null;
  }

  private created() {
    // Only if sync is running, poll
    if (this.isSyncing) {
      this.timer = window.setInterval(() => {
        this.$emit('refetch');
      }, 5000);
    }
  }

  @Watch('isSyncing')
  private isSyncingChanged(isSyncing: boolean) {
    if (!isSyncing && this.timer) {
      clearInterval(this.timer);
    }
  }
}
