






import {Component, Prop, Vue} from 'vue-property-decorator';
import AppraisalList from '@/components/appraisal/AppraisalList.vue';
import {Appraisal} from '@/types/auction';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({
  components: {Card, AppraisalList},
})
export default class AppraisalsCard extends Vue {
  @Prop(Array) protected appraisals!: Appraisal[];
}
