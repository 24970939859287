













import {Component, Prop, Vue} from 'vue-property-decorator';

export enum SnapshotCheckType {
  ERROR,
  WARNING,
  SUCCESS,
  NO_INFORMATION,
}

@Component({})
export default class SnapshotCheck extends Vue {
  @Prop(Boolean) protected checked!: boolean;
  @Prop(Number) protected type!: SnapshotCheckType;
  @Prop(String) protected title!: string;
  @Prop(String) protected content?: string;
  @Prop(String) protected tooltip?: string;

  private SnapshotCheckType = SnapshotCheckType;
}
