










import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection } from '@/types/intrador';
import SnapshotCheck, {SnapshotCheckType} from '@/components/snapshot/anomalies/SnapshotCheck.vue';

@Component({ components: { SnapshotCheck } })
export default class CheckPictureInPictureAnomaly extends Vue {
  @Prop(Object) protected inspection!: Inspection_inspection;

  get checks() {
    return this.inspection.checks!.filter((check) => check.namespace === 'PictureInPicture');
  }

  get checksError() {
    return this.checks.filter((check) => check.level === 'ERROR');
  }

  get checksWarning() {
    return this.checks.filter((check) => check.level === 'WARNING');
  }

  get type() {
    // If we have no checks, this is unknown
    if (this.checks.length < 1) {
      return SnapshotCheckType.NO_INFORMATION;
    }

    // Error much?
    if (this.checksError.length > 0) {
      return SnapshotCheckType.ERROR;
    }

    // Warning much?
    if (this.checksWarning.length > 0) {
      return SnapshotCheckType.WARNING;
    }

    // No checks found with status other than ERROR/WARNING, so SUCCESS
    return SnapshotCheckType.SUCCESS;
  }

  get content() {
    // We do not know yet
    if (this.checks.length === 0) {
      return this.$it('snapshot.detail.anomaly.ai-check.not-checked', 'Not checked yet');
    }

    // If we have any errors, show the user
    if (this.checksWarning.length > 0 || this.checksError.length > 0) {
      return this.$it('snapshot.detail.anomaly.ai-check.found', 'AI found anomalies in some pictures');
    }

    // No errors found, return success
    return this.$it('snapshot.detail.anomaly.ai-check.nothing-found', 'AI did not find anomalies in pictures');
  }

}
