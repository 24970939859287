



















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Inspection_inspection, InspectionStatus} from '@/types/intrador';

@Component({})
export default class InspectionHold extends Vue {
  @Prop(Object) protected inspection!: Inspection_inspection;

  get isOnHold() {
    return this.inspection.status === InspectionStatus.ON_HOLD;
  }

  get lastHold() {
    // If we do not have holds, stop
    if (!this.inspection.holds) {
      return null;
    }

    return this.inspection.holds.length > 0 ? this.inspection.holds[this.inspection.holds.length - 1] : null;
  }
}
