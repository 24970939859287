































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection } from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({ components: { Card } })
export default class InspectionDetails extends Vue {
  @Prop(Object) protected inspection!: Inspection_inspection;

  private get vendorInvoiceNumber() {
    if (this.inspection && this.inspection.asset && this.inspection.asset.meta) {
      return this.inspection.asset.meta.find((meta: any) => meta.key === 'vendor_invoice_number')?.value;
    }
  }
}
