









import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Inspection_inspection,
  Inspection_inspection_checks,
} from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';
import InspectionDistanceAnomaly from '@/components/inspection/anomalies/InspectionDistanceAnomaly.vue';
import InspectionInconsistencyAnomaly from '@/components/inspection/anomalies/InspectionInconsistencyAnomaly.vue';
import InspectionPictureInPictureAnomaly from '@/components/inspection/anomalies/InspectionPictureInPictureAnomaly.vue';
import InspectionSerialNumberAnomaly from '@/components/inspection/anomalies/InspectionSerialNumberAnomaly.vue';
import InspectionSoldDateAnomaly from '@/components/inspection/anomalies/InspectionSoldDateAnomaly.vue';
import InspectionTimesInDemonstrationAnomaly from '@/components/inspection/anomalies/InspectionTimesInDemonstrationAnomaly.vue';
import InspectionTimesInRentalAnomaly from '@/components/inspection/anomalies/InspectionTimesInRentalAnomaly.vue';
import InspectionWarrantyRegisteredAnomaly from '@/components/inspection/anomalies/InspectionWarrantyRegisteredAnomaly.vue';

export interface Checks {
  [key: string]: Inspection_inspection_checks;
}

@Component({
  components: {
    Card,
    InspectionDistanceAnomaly,
    InspectionInconsistencyAnomaly,
    InspectionPictureInPictureAnomaly,
    InspectionSerialNumberAnomaly,
    InspectionSoldDateAnomaly,
    InspectionTimesInDemonstrationAnomaly,
    InspectionTimesInRentalAnomaly,
    InspectionWarrantyRegisteredAnomaly,
  },
})
export default class InspectionAnomalies extends Vue {
  @Prop(Object) protected inspection!: Inspection_inspection;

  /**
   * Convert list to dictionary based on namespace
   *
   * @private
   */
  private get checks(): Checks {
    // Should keep last checks (if there is ever any overlap)
    return (this.inspection.checks || [])
      .sort((a, b) => {
        // If the levels are equal, compare with oldest
        if (a.level === b.level) {
          return parseInt(b.id, 10) - parseInt(a.id, 10);
        }

        if (a.level === 'ERROR') {
          return -1;
        } else if (a.level === 'WARNING') {
          return -1;
        }

        return 1;
      })

      .reduce((d: Checks, c: Inspection_inspection_checks) => {
        if (c.namespace in d) {
          return d;
        }

        return {
          ...d,
          [c.namespace]: {
            ...c,
          },
        };
      }, {});
  }

  /**
   * Ordered list of checks
   */
  private get checksOrdered() {
    // Default checks
    const checks: { [key: string]: Inspection_inspection_checks | null } = {
      SerialNumber: this.checks.SerialNumber,
      PictureInPicture: this.checks.PictureInPicture,
      Distance: this.checks.Distance,
      Inconsistency: this.checks.Inconsistency,
      Warranty: null,
      SoldDate: null,
      TimesInDemonstration: null,
      TimesInRental: null,
    };

    // Conditional checks (only shown when present)
    if (this.checks.SoldDate) {
      checks.SoldDate = this.checks.SoldDate;
    }

    if (this.checks.TimesInDemonstration) {
      checks.TimesInDemonstration = this.checks.TimesInDemonstration;
    }

    if (this.checks.TimesInRental) {
      checks.TimesInRental = this.checks.TimesInRental;
    }

    if (this.checks.WarrantyRegistered) {
      checks.WarrantyRegistered = this.checks.WarrantyRegistered;
    }

    return checks;
  }
}
