



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection_contact, Inspection_inspection_inspector } from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({ components: { Card } })
export default class InspectionUserView extends Vue {
  @Prop(String) protected title!: string;
  @Prop(Boolean) protected primary?: boolean;
  @Prop(Object) protected user?: Inspection_inspection_inspector | Inspection_inspection_contact;
  // TODO: Add account manager
}
