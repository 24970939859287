

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  Inspection_inspection_approvals,
  Inspection_inspection_approvals_creator,
  Inspection_inspection_approvals_reasons,
  Inspection_inspection_holds,
  Inspection_inspection_holds_creator,
  Inspection_inspection_transfers,
  Inspection_inspection_transfers_address,
  Inspection_inspection_transfers_inspector,
  InspectionApprovalState, InspectionTransferStatus,
} from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';

interface InspectionStatusUpdate {
  type: string;
  creator: Inspection_inspection_approvals_creator | Inspection_inspection_holds_creator
    | Inspection_inspection_transfers_inspector | null;
  createdAt?: string | null;
  declinedAt?: string | null;
  updatedAt?: string | null;
  reasons?: Inspection_inspection_approvals_reasons[];
  remark?: string | null;
  message?: string | null;
  resolvedAt?: string | null;
  state?: InspectionApprovalState | null;
}

enum InspectionStatusType {
  APPROVAL = 'APPROVAL',
  ON_HOLD = 'ON_HOLD',
}

@Component({ components: { Card } })
export default class InspectionUpdates extends Vue {
  @Prop(Array) protected approvals!: Inspection_inspection_approvals[];
  @Prop(Array) protected holds!: Inspection_inspection_holds[];

  private inspectionStatusType = InspectionStatusType;
  private InspectionApprovalState = InspectionApprovalState;

  private get statusUpdates() {
    const updates: InspectionStatusUpdate[] = [];
    if (this.approvals) {
      this.approvals.forEach((approval: Inspection_inspection_approvals) => {
        updates.push({
          type: this.inspectionStatusType.APPROVAL,
          creator: approval.creator,
          createdAt: approval.createdAt,
          reasons: approval.reasons,
          remark: approval.remark,
          state: approval.state,
        });
      });
    }
    if (this.holds) {
      this.holds.forEach((hold: Inspection_inspection_holds) => {
        updates.push({
          type: this.inspectionStatusType.ON_HOLD,
          creator: hold.creator,
          createdAt: hold.createdAt,
          message: hold.message,
          resolvedAt: hold.resolvedAt,
        });
      });
    }
    updates.sort((a: any, b: any) => (a.createdAt > b.createdAt) ? -1 : ((a.createdAt < b.createdAt) ? 1 : 0));
    return updates;
  }
}
