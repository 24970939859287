











































import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';
import InspectionFormatter from '@/plugins/inspection/InspectionFormatter';
import Polaroid from '@/components/Polaroid.vue';
import GripProxy from '@/plugins/misc/GripProxy';

@Component({components: {Card, Polaroid}})
export default class InspectionImages extends Vue {
  @Prop(Object) protected formatter!: InspectionFormatter;
  @Prop(Object) protected gripProxy!: GripProxy;

  private get groups() {
    return this.formatter.images.map((ig) => ({
      ...ig,
      images: ig.images.filter((i) => i.deletedAt === null),
    }));
  }

  private click(id: string) {
    this.$emit('open', id);
  }
}
