















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection_logs, Inspection_inspection_logs_device } from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';

interface Device extends Inspection_inspection_logs_device {
  icon: string;
}

interface Devices {
  [key: string]: Device;
}

@Component({
  components: {
    Card,
  },
})
export default class InspectionDevices extends Vue {
  @Prop(Array) protected logs!: Inspection_inspection_logs[];

  private get devices(): Devices | null {
    const logs = (this.logs || []);
    if (logs.length < 1) {
      return null;
    }

    const devices: Devices = {};

    logs.forEach((l) => {
      if (l && l.device && l.device.id && !(l.device.id in devices)) {
        devices[l.device.id] = {
          ...l.device,
          icon: l.device.brand === 'Apple' ? 'fa-apple' : 'fa-android',
        };
      }
    });

    return devices;
  }
}
