import {ApolloQueryResult} from 'apollo-client';
import {graphqlClient} from '@/store/apollo/apollo';
import {
  AllGripResources_allGripResources,
  AllGripResources_allGripResources_predictions,
} from '@/types/intrador';

export default class GripProxy {

  private inspectionIID: string | null = null;
  private queryResult: ApolloQueryResult<any> | null = null;

  public setInspectionIID(iid: string) {
    this.inspectionIID = iid;
  }

  public async getGripAIData() {
    if (this.inspectionIID) {
      this.queryResult = await graphqlClient.query({
        query: require('@/graphql/queries/inspection/AllGripResources.gql'),
        variables: {iid: this.inspectionIID},
      });
    }
  }

  public getDataForImage(oiid: string): AllGripResources_allGripResources | null {
    if (!this.queryResult || !this.queryResult.data.allGripResources) {
      return null;
    }

    // tslint:disable-next-line:max-line-length
    return this.queryResult.data.allGripResources.find((x: AllGripResources_allGripResources) => x.external_id === oiid);
  }

  // tslint:disable-next-line:max-line-length
  public getDataForImageForParameter(oiid: string, parameterId: string): AllGripResources_allGripResources_predictions | null {
    const dataForImage = this.getDataForImage(oiid);

    if (!dataForImage || !dataForImage.predictions || dataForImage.predictions.length === 0) {
      return null;
    }

    // tslint:disable-next-line:max-line-length
    const predictionForParameter = dataForImage.predictions.find((x: AllGripResources_allGripResources_predictions) => x.parameter.id === parameterId);
    if (!predictionForParameter) {
      return null;
    }
    return predictionForParameter;
  }

}
