










import { Component, Prop, Vue } from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({ components: { Card } })
export default class InspectionRemark extends Vue {
  @Prop(String) protected remark?: string;
}
