













import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection } from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';
import CheckSerialNumberManually from '@/components/snapshot/anomalies/checks/CheckSerialNumberManually.vue';
import CheckPictureInPictureAnomaly from '@/components/snapshot/anomalies/checks/CheckPictureInPictureAnomaly.vue';

@Component({
  components: {
    CheckSerialNumberManually,
    CheckPictureInPictureAnomaly,
    Card,
  },
})
export default class SnapshotSerialNumberAnomalies extends Vue {
  @Prop(Object) protected inspection!: Inspection_inspection;
}
